.InputField {
  position: relative;
  padding: 1.5em 0 0;
  margin-top: 0.5em;
  /* width: 50%; */
}

.InputField__input {
  font-family: inherit;
  font-weight: 700;
  width: 100%;
  border: 0;
  border-bottom: 2px solid gray;
  outline: 0;
  font-size: 1.3em;
  color: #027180;
  padding: 0.538em 0;
  background: transparent;
  transition: border-color 0.2s;
}

.InputField__input::placeholder {
  color: transparent;
}

.InputField__input:placeholder-shown ~ .InputField__label {
  font-size: 1.3em;
  cursor: text;
  top: 20px;
}

.InputField__input:focus {
  padding-bottom: 0.461em;
  font-weight: 700;
  transition: font-weight 0.2s;
  border-width: 3px;
  border-image: linear-gradient(to right, #11998e, #38ef7d);
  border-image-slice: 1;
}

.InputField__input:focus ~ .InputField__label {
  position: absolute;
  top: 0;
  display: block;
  transition: 0.2s;
  font-size: 1em;
  color: #11998e;
  font-weight: 700;
}

.InputField__input:required,
.InputField__input:invalid {
  box-shadow: none;
}

.InputField__label {
  position: absolute;
  top: 0;
  display: block;
  transition: 0.2s;
  font-size: 1em;
  color: #585858;
  /* color: gray; */
  /* z-index: -1; */
}
